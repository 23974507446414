import Mustache from 'mustache';
import filter from 'lodash/filter.js';
import mapValues from 'lodash/mapValues.js';
import map from 'lodash/map.js';
import forEach from 'lodash/forEach.js';
import keys from 'lodash/keys.js';
import includes from 'lodash/includes.js';
import keyBy from 'lodash/keyBy.js';
import find from 'lodash/find.js';

export const parseField = (field) => {
  if (field.type && field.type.startsWith('Json')) {
    return parseJson(field.value);
  } else {
    return field.value;
  }
};

const parseJson = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const parseFields = (fields) => {
  const parsed = map(fields, (field) => ({
    ...field,
    value: parseField(field)
  }));
  return parsed;
};

export const getFieldsForContent = (block, culture) => {
  const filtered = filter(block.content, (content) => content.culture === culture);
  if (filtered.length >= 1) {
    return parseFields(filtered[0].fields);
  }
  return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
  const content = getFieldsForContent(block, culture);
  return mapValues(keyBy(content, 'name'), 'value');
};

export const isBrowserSupported = () => {
  const { detect } = require('detect-browser');
  const browser = detect();
  const name = browser?.name;

  if (name === 'ie') {
    return false;
  } else {
    return true;
  }
};

export const hasWindow = () => {
  let hasWindow = false;
  try {
    if (window) {
      hasWindow = true;
    }
  } catch (ex) {
    hasWindow = false;
  }

  return hasWindow;
};

export const parseText = (text, data) => {
  if (data) {
    return Mustache.render(text, data);
  }
  return text;
};

// export const defaultPageNames = {
//     home: '/',
//     vehicles: 'stock',
//     vehicleDetail: 'vehicle',
//     vehicleTakeOver: 'takeover',
//     vehicleCompare: 'vehicle-compare',
//     vehicleSearchAgent: 'search-agent',
//     vehicleReservation: 'reservation',
//     search: 'search',
//     contact: 'contact',
//     contactDetail: 'contact',
//     services: 'services',
//     about: 'about',
//     privacy: 'privacy',
//     blog: 'blog',
//     blogPost: 'blog-post',
//     favorites: 'favorites',
//     login: 'login',
//     registration: 'registration',
//     forgotPassword: 'forgot-password',
//     resetPassword: 'reset-password',
//     financingInformation: 'financing-information',
//     account: 'account',
//     accountPurchases: 'account-purchases',
//     accountOverview: 'account-overview',
//     accountProfile: 'account-profile',
//     accountChangePassword: 'account-change-password',
//     accountCarAgent: 'account-car-agent',
//     accountFavorites: 'account-favorites',
//     404: '404',
//     purchase: 'purchase',
//     purchaseLogin: 'purchase/login',
//     purchaseRegistration: 'purchase/registration',
// };
//
// export const PageTypes = keys(defaultPageNames).reduce((a, v) => ({...a, [v]: v}), {});

export const resolveUrlsMultiLanguage = (defaultPagePathsByPageTypeName, pages) => {
  // Used for BW compatible, but depricated
  let result = {};
  forEach(keys(defaultPagePathsByPageTypeName), (pageType) => {
    const pagesInDb = filter(pages, (p) => p.type === pageType || (p.type === 'index' && pageType === 'home'));
    const defaultPageValue = defaultPagePathsByPageTypeName[pageType];

    if (pagesInDb.length > 0) {
      forEach(pagesInDb, (p) => {
        let translations = JSON.parse(p.slug);

        forEach(keys(translations), (lng) => {
          if (translations[lng] === '' && defaultPageValue !== '/') {
            translations[lng] = defaultPageValue;
          }
        });

        result[p.url] = translations;
      });
    } else {
      result[defaultPageValue] = {
        en: defaultPageValue,
        nl: defaultPageValue,
        fr: defaultPageValue,
        de: defaultPageValue
      };
    }
  });

  const customPages = filter(pages, (p) => p.type === 'custom');
  forEach(customPages, (page) => {
    const defaultPageValue = page.url;
    let translations = JSON.parse(page.slug);

    forEach(keys(translations), (lng) => {
      if (translations[lng] === '') {
        translations[lng] = defaultPageValue;
      }
    });
    result[defaultPageValue] = translations;
  });

  return result;
};

export const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
};

export const resolveTranslationBySlug = (page, lng) => {
  const slug = tryParse(page.slug);
  if (slug && slug[lng] && slug[lng] !== '') {
    return slug[lng];
  } else {
    return page.url;
  }
};

export const parsePageUrl = (page, language, variables, data = {}) => {
  let result = page.url;

  // New return server generated paths!!
  if (page && page.paths) {
    result = JSON.parse(page.paths)[language];
  }

  if (variables) {
    // variables for seo pages /make/model/ f.ex
    result = `${result}${variables}/`;
  }

  if (result && result.indexOf('{{') > -1) {
    result = parseText(result, data);
  }

  return result;
};

export const isDynamicPage = (url) => {
  return url.indexOf('{{') > -1 || url.indexOf(':') > -1 || includes(url, 'dealerGroup');
};

export const isCreationOfPageAllowed = (page, pageTypes, pageCategoriesToIgnore) => {
  // pageCategories to ignore
  if (pageCategoriesToIgnore.includes(page.category)) {
    return false;
  }

  // resolve page_type of page
  const pageType = pageTypes.find((pt) => pt.name === page.type);
  return !pageType?.isTemplate;
};

export const b64DecodeUnicode = (str) => {
  try {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    ).replace(/&nbsp;/g, ' ');
  } catch (e) {
    return undefined;
  }
};

export const capitalize = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const mapArrayToString = (arr, separator = ',') => {
  let result = '';
  forEach(arr, (item) => {
    if (result === '') {
      result = item;
    } else {
      result = `${result}${separator}${item}`;
    }
  });
  return result;
};

export const addPathPrefixToJsonImageObject = (imageObject, pathPrefix) => {
  if (!pathPrefix || pathPrefix === '') {
    return imageObject;
  }

  let parsed = { ...imageObject };

  if (parsed['mobile'] && !parsed['mobile'].includes(pathPrefix)) {
    parsed['mobile'] = `${pathPrefix}${parsed['mobile']}`;
  }
  if (parsed['tablet'] && !parsed['tablet'].includes(pathPrefix)) {
    parsed['tablet'] = `${pathPrefix}${parsed['tablet']}`;
  }
  if (parsed['desktop'] && !parsed['desktop'].includes(pathPrefix)) {
    parsed['desktop'] = `${pathPrefix}${parsed['desktop']}`;
  }

  return parsed;
};
