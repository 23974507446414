module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","fr"],"defaultLanguage":"nl","redirect":false,"i18nextOptions":{"fallbackLng":"en","debug":false,"keySeparator":false,"nsSeparator":"::","returnEmptyString":false},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["nl","fr","en"]},{"matchPath":"/(.*)","getLanguageFromPath":true,"excludeLanguages":["nl","fr","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
